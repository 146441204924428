import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        defaultTheme: 'light',
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#353897',
                secondary: '#00c5b1',
                accent: '#09b23d',
            },
        },
    },
});



